import React, { useState, useRef, useEffect } from "react"

const AccordionBase = ({ children, isOpen }) => {
  const [accordionHeight, setAccordionHeight] = useState(0)
  const collapsedRef = useRef()

  useEffect(() => {
    if (!isOpen) {
      setAccordionHeight(0)
    } else {
      setAccordionHeight(collapsedRef.current.scrollHeight + "px")
    }
  }, [isOpen])

  const contentStyles = {
    maxHeight: accordionHeight,
    overflow: "hidden",
    transition: "max-height 0.2s ease-in-out"
  }
  return (
    <div className="accordion" style={contentStyles} ref={collapsedRef}>
      {children}
    </div>
  )
}

export default AccordionBase
